import { ListingData } from "@/server/interfaces/ListingData";
import React from "react";
import ListingItem from "./ListItem";

const ListingsList = ({ Listings }) => {
  return (
    <div className="gap-8 space-y-8 lg:columns-4 sm:columns-3 columns-2">
      {Listings.map((Listing: ListingData, index: number) => (
        <div className="" key={index}>
          <ListingItem Listing={Listing} />
        </div>
      ))}
    </div>
  );
};

export default ListingsList;

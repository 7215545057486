import { useTranslations } from "@/TranslationContext";
import { useRouter } from "next/router";
import React from "react";

const QuickLinks = () => {
  const router = useRouter();
  const t = useTranslations();

  const { locale } = router;

  const ls = locale === "sw" ? "" : `/${locale}`;

  const links = [
    {
      title: t.buyHouse,
      url: `${ls}${ls}/listings?listing_type=sale&property_type=house`,
      code: "buy-house",
    },
    {
      title: t.rentHouse,
      url: `${ls}/listings?listing_type=rent&property_type=house`,
      code: "rent",
    },
    {
      title: t.buyPlot,
      url: `${ls}/listings?property_type=residential_plot`,
      code: "land",
    },
    {
      title: t.buyFarms,
      url: `${ls}/listings?property_type=farm`,
      code: "farm",
    },
    {
      title: t.rentRetailSpace,
      url: `${ls}/listings?listing_type=rent&property_type=retail_space`,
      code: "frame",
    },
    {
      title: t.rentOfficeSpace,
      url: `${ls}/listings?listing_type=rent&property_type=office_space`,
      code: "office",
    },
    // {
    //   title: "Pay</br>by Installment",
    //   url: "/mortgage",
    //   code: "installment",
    // },
    {
      title: t.agents,
      url: `${ls}/sellers`,
      code: "agents",
    },
  ];

  return (
    <div
      className="w-full bg-white overflow-x-auto scroll-m-0 "
      style={{ scrollbarWidth: "none" }}
    >
      <div className="flex lg:justify-center items-center">
        <div className="flex space-x-4">
          {links.map((link, index) => {
            return (
              <a
                key={index}
                href={link.url}
                className="flex items-center space-x-2 text-gray-600"
              >
                <div className=" w-24 aspect-square grid place-content-center rounded-lg border-2 border-green-600/20  items-center justify-center">
                  <div>
                    <div className="grid place-content-center mb-2">
                      <img
                        src={`/assets/icons/quick-links/${link.code}.svg`}
                        className="w-8 aspect-square"
                      />
                    </div>
                    <div
                      className="text-xs text-center leading-0 "
                      dangerouslySetInnerHTML={{ __html: link.title }}
                    ></div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;

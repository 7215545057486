import { ListingData } from "@/server/interfaces/ListingData";
import React from "react";
import ListingsList from "./Lists";
import { useRouter } from "next/router";
import { useTranslations } from "@/TranslationContext";

const FeaturedListings = ({ Listings }: { Listings: ListingData[] }) => {
  const router = useRouter();
  const t = useTranslations();

  const { locale } = router;

  const ls = locale === "sw" ? "" : `/${locale}`;
  return (
    <div className="my-12">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3 className=" md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
          {t.recentlyAdded}
        </h3>
        <p className="text-slate-400 max-w-xl mx-auto">
          {t.recentlyAddedDescription}
        </p>
      </div>
      <div className="">
        <ListingsList Listings={Listings} />
      </div>
      <div className="text-center mt-20">
        <a
          href={`${ls}/listings`}
          className="inline-block py-4 px-8 text-white bg-green-600 hover:bg-green-700 rounded-lg shadow"
          title={t.seeAll}
        >
          {t.seeAll}
        </a>
      </div>
    </div>
  );
};

export default FeaturedListings;

import React from "react";
import SearchForm from "./SearchInputs";

const SearchArea = () => {
  return (
    <div className="p-6 bg-white   rounded-lg shadow-md ">
      <div role="tabpanel">
        <SearchForm queryParams={null} container="hero" />
      </div>
    </div>
  );
};

export default SearchArea;

"use client";
import React from "react";
import SearchArea from "./inputs/SearchArea";
import { useTranslations } from "@/TranslationContext";
import MobileSearch from "./inputs/MobileSearch";

const Hero = () => {
  const t = useTranslations();
  return (
    <div className="container-fluid -mt-16">
      <div
        className="relative py-7  md:!py-20  w-full   overflow-hidden"
        id="home"
      >
        <div className="absolute inset-0 bg-green-200"></div>
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="ltr:md:text-left rtl:md:text-right text-center">
              <h1
                className="font-bold text-green-800 lg:leading-normal leading-normal text-2xl lg:text-5xl mb-6"
                dangerouslySetInnerHTML={{ __html: t.heroTitle }}
              ></h1>
              <div className="hidden lg:!block">
                <SearchArea />
              </div>
              <div className="lg:hidden">
                <MobileSearch />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

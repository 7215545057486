import React from "react";
import SearchForm from "./SearchInputs";
import { LuDelete, LuSearch } from "react-icons/lu";
import SearchArea from "./SearchArea";
import { MdClose } from "react-icons/md";
import { useTranslations } from "@/TranslationContext";

const MobileSearch = () => {
  const [searchIsVisible, setSearchIsVisible] = React.useState(false);

  const t = useTranslations();

  return (
    <>
      <div
        onClick={() => setSearchIsVisible(true)}
        className="flex items-center rounded-full bg-white justify-between cursor-pointer hover:border-red-500 hover:bg-gray-500"
      >
        <div className="flex flex-1">
          <div className="font-bold ml-3 mr-3 text-gray-600">{t.search}</div>
          <div
            className="text-gray-500 text-left flex-1 text-ellipsis truncate overflow-hidden
          "
          >
            {t.searchPlaceholder}
          </div>
        </div>
        <div className="rounded-full w-10 aspect-square m-1 bg-green-600 grid place-content-center">
          <LuSearch className="icons text-white" />
        </div>
      </div>
      {searchIsVisible ? (
        <div className="fixed bg-white inset-0 z-50 pt-20">
          <div className="py-4 flex justify-end">
            <button onClick={() => setSearchIsVisible(false)} className="">
              <MdClose className="icons text-2xl text-gray-500 mr-4" />
            </button>
          </div>
          <SearchArea />
        </div>
      ) : null}
    </>
  );
};

export default MobileSearch;

import QuickLinks from "@/components/inputs/QuickLinks";
import Hero from "../components/Hero";
import SiteLayout from "@/components/layouts/SiteLayout";
import FeaturedListings from "@/components/lists/FeaturedListings";
import ListingController from "@/server/controllers/ListingController";
import { useRouter } from "next/router";
import CommonUtils from "@/utils/CommonUtils";

export async function getServerSideProps(context: any) {
  const ListingsData = await ListingController.getAllListings(
    1,
    10,
    context.query
  );
  const Listings = JSON.parse(JSON.stringify(ListingsData.data));

  return { props: { Listings } };
}

export default function Home({ Listings }) {
  const router = useRouter();
  const { locale } = router;

  let fallbackTitleSw = CommonUtils.fallbackTitleSw;
  let fallbackTitleEn = CommonUtils.fallbackTitleEn;

  let fallbackDescriptionSw = CommonUtils.fallbackDescriptionSw;
  let fallbackDescriptionEn = CommonUtils.fallbackDescriptionEn;

  let fallbackTitle = locale === "sw" ? fallbackTitleSw : fallbackTitleEn;

  let fallbackDescription =
    locale === "sw" ? fallbackDescriptionSw : fallbackDescriptionEn;

  let title = fallbackTitle;
  let description = fallbackDescription;

  return (
    <SiteLayout title={title} description={description}>
      <Hero />
      <div className="container mx-auto">
        <div className="mt-8 lg:!mt-20">
          <QuickLinks />
        </div>
        <FeaturedListings Listings={Listings} />
      </div>
    </SiteLayout>
  );
}
